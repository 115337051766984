import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../../../sections/Navbar/Navbar'
import Footer from '../../../sections/Footer'
import { PrismicRichText } from "@prismicio/react";
import "../../../styles/AdminPage.scss";
import ReactPlayer from 'react-player';

const ImplementationForUsers = ({ data }: any) => {
  const info = data.prismicImplementationForUsers.dataRaw
  const imgInfo = data.prismicImplementationForUsers.data
  return (
    <div>
      <Navbar />
      <div className='AdminPage'>
        <div className='AdminPage-title'>
          <PrismicRichText field={info.tite} />
        </div>
        <div className='AdminPage-second-title'>
          <PrismicRichText field={info.updated} />
        </div>
        <div className="AdminPage-video">
          <ReactPlayer
            playing={true}
            muted={true}
            loop={true}
            url={info.yt.url}
          />
        </div>
        <div className='AdminPage-content'>
          <PrismicRichText field={info.texte} />
          <img className="AdminPage-image" src={imgInfo.im2.url} alt={imgInfo.im2.alt} />
          <img className="AdminPage-image" src={imgInfo.im3.url} alt={imgInfo.im3.alt} />
        </div>
      </div>
      <Footer />
    </div>
  )
};

export default ImplementationForUsers;

export const query = graphql`
query ImplementationForUsers {
  prismicImplementationForUsers(lang: {eq: "en-us"})  {
    dataRaw
    data {
      im2 {
        url
        alt
      }
      im3 {
        alt
        url
      }
      logo {
        alt
        url
      }
    }
  }
}`;

